document.addEventListener('DOMContentLoaded', function() {
    const form = document.getElementById('contactForm');
    form.addEventListener('submit', async function(event) {
        event.preventDefault();

        const formData = new FormData(form);        
        const fields = [];
        let legalConsentOptions = null;
        formData.forEach((value, key) => {
            if (key === 'form_privacy_consent') {
                // Handle GDPR consent separately
                legalConsentOptions = {
                    consent: {
                        consentToProcess: true,
                        text: "Ich bin damit einverstanden, dass die nexum AG meine personenbezogenen Daten speichert und verarbeitet.",
                        communications: [
                            {
                                value: true,
                                subscriptionTypeId: 999,
                                text: "Ich bin damit einverstanden, von der nexum AG Marketingmitteilungen zu erhalten."
                            }
                        ]
                    }
                };
                fields.push({
                    objectTypeId: "0-1",
                    name: key,
                    value: value === 'on'
                });
            } else {
                fields.push({
                    objectTypeId: "0-1",
                    name: key,
                    value: value
                });
            }
        });

        const data = {
            submittedAt: Date.now(),
            fields: fields,
            context: {
                // hutk: getCookie('hubspotutk'), // Function to get the hubspotutk cookie value
                pageUri: window.location.href,
                pageName: document.title
            },
            legalConsentOptions: legalConsentOptions
        };
        console.log('data:', data);

        try {
            const response = await fetch('https://api.hsforms.com/submissions/v3/integration/submit/143181621/cc1e4db9-f8cd-4165-9ece-29592eed0308', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                window.location.href = 'https://orientierung.nexum.com/thank-you.html';
            } else {
                console.error('Form submission failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    });
});